/* Pagination Helprs */
import React from 'react'
import SVG from 'react-inlinesvg'
import Sort1 from '../../images/svg/icons/Shopping/Sort1.svg'
import Up2 from '../../images/svg/icons/Navigation/Up-2.svg'
import Down2 from '../../images/svg/icons/Navigation/Down-2.svg'

export const sortCaret = (order, column) => {
  if (!order)
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1 svg-icon-sort">
        <SVG src={Sort1} />
      </span>
    )
  else if (order === 'asc')
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
        <SVG src={Up2} />
      </span>
    )
  else if (order === 'desc')
    return (
      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
        <SVG src={Down2} />
      </span>
    )
  return null
}

export const headerSortingClasses = (
  column,
  sortOrder,
  isLastSorting,
  colIndex,
) => (sortOrder === 'asc' || sortOrder === 'desc' ? 'sortable-active' : '')
