export function getQueryParams(search = window.location.search.substring(1)) {
  let searchValue = decodeURI(search)
  if (searchValue !== '') {
    searchValue = searchValue.replace(/"/g, '\\"')
    searchValue = searchValue.replace(/&/g, '","')
    searchValue = searchValue.replace(/=/g, '":"')
    searchValue = `"${searchValue}"`
  }
  let queryParams = '{}'
  try {
    JSON.parse(`{${searchValue}}`)
    queryParams = `{${searchValue}}`
  } catch (e) {
  }
  return JSON.parse(queryParams);
}

export const urlEncode = params => {
  return Object.keys(params)
    .map(function (i) {
      return encodeURIComponent(i) + '=' + encodeURIComponent(params[i])
    })
    .join('&')
}
