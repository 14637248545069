import React, {createContext, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {publicFetch} from "../utils/fetch";

const AuthContext = createContext()
const {Provider} = AuthContext

const AuthProvider = ({children}) => {
  const token = localStorage.getItem('token')
  const userInfo = localStorage.getItem('userInfo')
  const expiresIn = localStorage.getItem('expiresIn')
  const navigate = useNavigate()
  const [authState, setAuthState] = useState({
    token,
    expiresIn,
    userInfo: userInfo ? JSON.parse(userInfo) : {},
  })
  const permissionInfo = localStorage.getItem('permissionInfo')
    const [permissionState, setPermissionState] = useState({
        permissionInfo: permissionInfo ? JSON.parse(permissionInfo) : {}
    })

  const setAuthInfo = ({token, userInfo, expiresIn}) => {
    setLocalStorageToken(token)
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
    localStorage.setItem('expiresIn', expiresIn)
    setAuthState(prevAuthState => ({
      ...prevAuthState, userInfo: userInfo, expiresIn: expiresIn,token: token
    }))
  }


  const setLocalStorageToken = (token) => {
    if (localStorage.getItem('token') !== token) {
      localStorage.setItem('token', token)
      // setInterval(async () => {
      //   const {data} = await publicFetch.get('auth/getRefreshToken', {headers: {"Authorization": `Bearer ${token}`}})
      //   setLocalStorageToken(data.data.token)
      // }, 1200000);
      // localStorage.removeItem('token')
      // localStorage.setItem('token', token)
      setAuthState(prevAuthState => ({
        ...prevAuthState, token: token
      }))
    }
  }

  const setPermissionInfo = (permissionInfo) => {
    localStorage.setItem('permissionInfo', JSON.stringify(permissionInfo))
    setPermissionState({
      permissionInfo
    })
  }

  const logout = () => {
    removeLocalStorage()
    navigate('/login')
  }
  const removeLocalStorage = () =>{
    localStorage.removeItem('token')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('expiresIn')
    localStorage.removeItem('permissionInfo')
    localStorage.removeItem('tokenStatus')
    setAuthState({})
  }
  const isAuthenticated = () => {
    if (!authState.token || !authState.expiresIn) {
      return false
    }
    // return (
    //   new Date().getTime() / 1000 < authState.expiresIn
    // );
    return true
  }

  // const isAdmin = () => {
  //   return authState.userInfo.role === 'admin';
  // };

  return (
    <Provider
      value={{
        authState,
        permissionState,
        setAuthState: authInfo => setAuthInfo(authInfo),
        setLocalStorageToken: token => setLocalStorageToken(token),
        setPermissionState: permissionInfo => setPermissionInfo(permissionInfo),
        logout,
        removeLocalStorage,
        isAuthenticated,
      }}
    >
      {children}
    </Provider>
  )
}

export {AuthContext, AuthProvider}
