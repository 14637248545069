function FullPageSpinner() {
  return <div className="centered"><span className="spinner spinner-primary"/></div>
}

function FullPageErrorFallback({error}) {
  return (
    <div
      role="alert"
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <p>Uh oh... There's a problem. Try refreshing the app.</p>
      <pre>{error.message}</pre>
    </div>
  )
}

export {FullPageErrorFallback, FullPageSpinner}
