import * as React from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import {AuthProvider} from './auth-context'
import {FetchProvider} from './fetch-context'

document.title = process.env.REACT_APP_NAME

function AppProviders({children}) {
  return (
    <Router>
      <AuthProvider>
        <FetchProvider>{children}</FetchProvider>
      </AuthProvider>
    </Router>
  )
}

export {AppProviders}
