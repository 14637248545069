import React, {useContext, useEffect, useState} from 'react'
import {AuthContext} from './context/auth-context'
import {FullPageSpinner} from './components/lib'
import {publicFetch} from "./utils/fetch";
import {Helmet} from "react-helmet-async";
import {capitalizeFirstLetter, getPageTitle} from "./utils/helpers";
import {FetchContext} from "./context/fetch-context";
import $ from 'jquery'

const AuthenticatedApp = React.lazy(() => import('./authenticated-app'))
const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'))

function App() {
    const authContext = useContext(AuthContext)
    const fetchContext = useContext(FetchContext)

    const siteDataInit = {
        logo: '',
        app_name: '',
        org_name: '',
        favicon: ''
    };
    const [siteData, setSiteData] = useState(siteDataInit);
    useEffect(async () => {
        const settingResponse = await publicFetch.get(`get-settings`)
        const siteDataFromResponse = settingResponse.data.data
        siteDataFromResponse.title = `${getPageTitle() ? `${getPageTitle()} - ` : ''}`
        process.env.REACT_APP_ENV !== 'local' ?
            siteDataFromResponse.title += siteDataFromResponse.app_name
            : siteDataFromResponse.title += capitalizeFirstLetter(process.env.REACT_APP_ENV);
        setSiteData(siteDataFromResponse)
        window.addEventListener('storage', ({key, newValue}) => {
            if (key === 'token') {
                authContext.setLocalStorageToken(newValue);
            }
        });
    }, [window.location.pathname])

    useEffect(async () => {
        async function getRefreshToken() {
            if (authContext.isAuthenticated()) {
                
                let status = localStorage.getItem('tokenStatus')
                if (status === 'EXPIRING') {
                    // perform token refresh
                    const {data} = await publicFetch.get('auth/getRefreshToken', {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
                    authContext.setLocalStorageToken(data.data.token)
                }
            }
        }

        const interval = setInterval(() => getRefreshToken(), 10000)
        return () => {
            clearInterval(interval);
        }
    }, [])

    return (
        <React.Suspense fallback={<FullPageSpinner/>}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{siteData.title}</title>
                <link rel="icon" href={siteData.favicon}/>
            </Helmet>
            {authContext.isAuthenticated() ? <AuthenticatedApp/> : <UnauthenticatedApp/>}
            {/*<AuthenticatedApp />*/}
        </React.Suspense>
    )
}

export default App
