import saveAs from 'file-saver';

const mimeTypes = {
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
    pdf: {
        type: 'application/pdf'
    },
    doc: {
        type: 'application/msword'
    },
    docx: {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    },
    txt: {
        type: 'text/plain'
    },
    xlsx: {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    zip: {
        type: 'application/zip'
    },
};
const config = {
    responseType: 'arraybuffer',
};
export const downloadFileByUrl = async (fetchContext, endpoint, fileName, fileType, method = 'get', data = []) => {
    let response = [];
    if (method === 'post') {
        response = await fetchContext.authAxios.post(endpoint, data, config);
    } else if (method === 'get') {
        response = await fetchContext.authAxios.get(endpoint, config)
    }
    var mimeType = fileType ? mimeTypes[fileType] : {};
    const blob = new Blob([response.data], mimeType);
    saveAs(blob, fileName || getFilename(response));
}
export const downloadFileByUrlWithoutMime = async (fetchContext, endpoint, fileName, method = 'get', data = []) => {
    let response = [];
    if (method === 'post') {
        response = await fetchContext.authAxios.post(endpoint, data, config);
    } else if (method === 'get') {
        response = await fetchContext.authAxios.get(endpoint, config)
    }
    const blob = new Blob([response.data], {type: response.headers["content-type"]});
    saveAs(blob, fileName || getFilename(response));
}
const getFilename = response => {
    let filename = '';
    const disposition = response.request.getResponseHeader('Content-Disposition');

    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]=((['"]).?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
    }
    // return filename;
    return response.request.getResponseHeader('Content-Disposition').split('filename=')[1].split(';')[0];
};
export const saveDataAsFile = response => {
    const blob = new Blob([response.data], {type: response.headers["content-type"]});
    saveAs(blob, getFilename(response));
}
