import React, {createContext, useContext} from 'react'
// import { useParams } from "react-router-dom";
import axios from 'axios'
import {AuthContext} from './auth-context'
import {Link, useParams, useNavigate} from 'react-router-dom'
import {urlEncode} from "../_metronic/_helpers";

const FetchContext = createContext()
const {Provider} = FetchContext
const FetchProvider = ({children}) => {
  const { id } = useParams()
  // console.log(id)
  const authContext = useContext(AuthContext)
  const navigate = useNavigate();
  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  })
  const token = authContext.authState.token;
  authAxios.interceptors.request.use(
    config => {
      config.headers.Authorization = `Bearer ${token}`
      return config
    },
    error => {
      return Promise.reject(error)
    },
  )

  authAxios.interceptors.response.use(
    response => {

      // update tokenStatus in storage
      localStorage.setItem('tokenStatus', response.headers['x-token-status'])

      return response
    },
    error => {
      const code = error && error.response ? error.response.status : 0
      const errorMessage =
        error && error.response.data.errors ? error.response.data.meta?.errorMessage:error.response.data?.message
      // const message =
      //     error && error.response ? error.response.status : 0;
      if (code === 403) {
        console.log('error code', code)
        const redirect = (window.location.pathname !== '/unauthorized') ? '?redirect=' + encodeURIComponent(window.location.pathname) : '';
        navigate('unauthorized' + redirect)
        return false
      }
      console.log(error.response.data.meta?.errorMessage)
      if (code === 401 && errorMessage === 'Unauthenticated.' && error.response.data.errors[0] !== "The token has been blacklisted") {
        if(window.location.pathname != '/quotation/client-view/'+id){
        authContext.removeLocalStorage();
        const redirect = (window.location.pathname !== '/login') ? '?redirect=' + encodeURIComponent(window.location.pathname) : '';
        navigate('login' + redirect)
      }
    }
      return Promise.reject(error)
    },
  )

  return (
    <Provider
      value={{
        authAxios,
      }}
    >
      {children}
    </Provider>
  )
}

export {FetchContext, FetchProvider}
