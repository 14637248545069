import React from 'react'

function useToggle(initialValue = false) {
    const [value, setValue] = React.useState(initialValue)
    const toggle = React.useCallback(() => {
        setValue(v => !v)
    }, [])
    return [value, toggle]
}

function nl2br(str, replaceMode = true, isXhtml = true) {
    var breakTag = (isXhtml) ? '<br />' : '<br>';
    var replaceStr = (replaceMode) ? '$1' + breakTag : '$1' + breakTag + '$2';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
}

function bytesToSize(bytes) {
    if (!bytes) {
        return '-'
    }
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return '0 Byte'
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    return (bytes / Math.pow(1024, i)).toFixed(i !== 0 ? 2 : 0) + ' ' + sizes[i]
}

function getPageTitle() {
    const pathArray = window.location.pathname.split('/').filter(path => path);
    if (pathArray.length === 0) {
        return "";
    }
    let pageTitle = pathArray[pathArray.length - 1];
    const pageTitlesToSkip = ['create', 'edit', 'view'];
    const newPathArray = pathArray.filter(p => isNaN(parseInt(p))).filter(p => !pageTitlesToSkip.includes(p))
    pageTitle = newPathArray[newPathArray.length - 1];

    let splitStr = pageTitle.replace(new RegExp('-', 'g'), ' ').split(" ");
    const regexExp = /^[a-f0-9]{32}$/gi;
    if (regexExp.test(splitStr)) {
        pageTitle = newPathArray[newPathArray.length - 2];
        splitStr = pageTitle.replace(new RegExp('-', 'g'), ' ').split(" ");
    }
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = capitalizeFirstLetter(splitStr[i]);
    }
    return splitStr.join(' ');
}

function getBreadcrumbs() {
    const regexExp = /^[a-f0-9]{32}$/gi;
    const pathArray = window.location.pathname.split('/').filter(path => path);
    if (pathArray.length === 0) {
        return [];
    }
    let mappedPathArray = [];
    for (var i = 0; i < pathArray.length; i++) {
        if (isNaN(parseInt(pathArray[i]))) {
            let splitStr = pathArray[i].replace(new RegExp('-', 'g'), ' ').split(" ");
            if (regexExp.test(splitStr)) {
                break;
            }
            for (var j = 0; j < splitStr.length; j++) {
                splitStr[j] = capitalizeFirstLetter(splitStr[j]);
            }
            mappedPathArray[i] = splitStr.join(' ')
        }
    }
    return mappedPathArray;
}

function capitalizeFirstLetter(string) {
    return decodeURI(string.charAt(0).toUpperCase() + string.substring(1))
}

// function isValidMd5(md5 = '') {
//     return md5.match('/^[a-f0-9]{32}$/');
// }
function getAppProjectName() {
    return process.env.REACT_APP_NAME.replace(' ', '-').toUpperCase()
}

// eslint-disable-next-line no-extend-native
Number.prototype.toFixed = function (fractionDigits) {
    var precisionTens = Math.pow(10, fractionDigits || 0);
    return (Math.round(Number(this) * precisionTens) / precisionTens).toPrecision(parseInt(this).toString().length + fractionDigits);
}

function account_code_display_format (account) {
    return `(${account.account_code}) ${account.account_name}`;
  };

export { useToggle, nl2br, bytesToSize, getPageTitle, getBreadcrumbs, capitalizeFirstLetter, getAppProjectName, account_code_display_format }
